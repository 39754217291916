import { call, all, takeLatest, put, select } from "redux-saga/effects";
import { find } from "lodash";

import CONFIG from "configs/config";

import { ROUTE_CHANGE } from "redux/withRouteChange";
import { setInitialSlide } from "containers/Sanitize/actions";
import { getHrId } from "containers/User/selectors";
import { getDisclaimerStatus } from "containers/Home/selectors";
import {
  rsGetPptDetails,
  rsGetSlides
} from "containers/Sanitize/sagas/resourceSagas";
import { handleCatchedError } from "utils/sagaUtils";
import {
  getFilesAction,
  setDisclaimerStatusAction
} from "containers/Home/actions";
import { getUserDetailsSaga } from "containers/User/sagas";

// Common route change listener saga, which will be calling effects when page is inititally loaded
export default function* routerSaga() {
  yield takeLatest(ROUTE_CHANGE, function* onRouteChange(action) {
    try {
      const { match } = action.payload;
      const userHrIdFromState = yield select(getHrId);
      switch (match.path) {
        case CONFIG.APP_ROUTES.LOGIN_CALLBACK: {
          yield put(setDisclaimerStatusAction(true));
          break;
        }
        case CONFIG.APP_ROUTES.PRIVATE.HOME: {
          if (!userHrIdFromState) {
            yield call(getUserDetailsSaga);
          }
          yield put(getFilesAction());

          break;
        }
        case CONFIG.APP_ROUTES.PRIVATE.SANITIZE: {
          const { id: pptId } = match.params || {};

          if (yield select(getDisclaimerStatus)) {
            // Reset disclaimer modal state to false, if user is logging in directly to sanitize page,
            // as we show disclaimer only on home page.
            yield put(setDisclaimerStatusAction(false));
          }
          // when loading the page directly, we need to fetch user object with profile image
          if (!userHrIdFromState) {
            yield call(getUserDetailsSaga);
          }
          if (pptId) {
            const [slidesList, pptDetails] = yield all([
              call(rsGetSlides, pptId),
              call(rsGetPptDetails, pptId)
            ]);
            const {
              lastActiveSlideNo,
              lastActiveElementType
              // lastActiveElementId // can be used in future if needed to navigate to element level
            } = pptDetails?.lastElementInfo || {};
            if (slidesList?.length) {
              const slide =
                find(slidesList, { slideIndex: lastActiveSlideNo }) ||
                slidesList[0];
              if (slide) {
                yield put(setInitialSlide({ slide, lastActiveElementType }));
              }
            }
          }
          break;
        }
        default:
          break;
      }
    } catch (e) {
      yield call(handleCatchedError, e);
    }
  });
}
