import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Container, Grid, Header, Placeholder } from "semantic-ui-react";
import { useOktaAuth } from "@okta/okta-react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import "./Home.scss";
import CONFIG from "configs/config";

import DisclaimerFooter from "components/DisclaimerFooter/DisclaimerFooter";
import DisclaimerModal from "components/DisclaimerModal/DisclaimerModal";
import PageHeader from "components/PageHeader/PageHeader";
import UploadFiles from "components/UploadFiles/UploadFiles";
import FilesListPlaceholder from "components/FilesListPlaceholder/FilesListPlaceholder";
import FilesList from "components/FilesList/FilesList";
import DeleteConfirmationModal from "components/DeleteConfirmationModal/DeleteConfirmationModal";
import FileListLoader from "components/FileListLoader/FileListLoader";
// import Version from "components/Version/Version";

import {
  resetHomeStore,
  uploadPowerPointAction,
  deleteFileAction,
  setDisclaimerStatusAction,
  autoSanitizeDocument,
  setSelectedPPTId
} from "./actions";
import {
  getFilesList,
  getDisclaimerStatus,
  isDeletingDocument,
  isAutoSanitizing
} from "./selectors";

import { fileListType } from "utils/customPropTypes";
import UserGuideFloatingIcon from "components/UserGuideFloatingIcon/UserGuideFloatingIcon";
import AutoSanitizationConfirmModal from "components/AutoSanitizationConfirmModal";
import {
  getSignedTokenFromStore,
  getUserDetails
} from "containers/User/selectors";
import { getSelectedFileAfterDelete } from "utils/common";
import { AAPageLoad } from "analytics/AnalyticsCapture";

/**
 * HOME SCREEN
 * User can upload PPT files
 * User can see uploaded files
 * @return JSX
 */
const Home = ({
  filesList,
  uploadPowerPoint,
  deleteFileAction,
  showDisclaimer,
  setDisclaimer,
  isDeletingFile,
  autoSanitize,
  isAutoSanitizing,
  resetHomeStore,
  setSelectedPPTId,
  userDetails
}) => {
  const { authState } = useOktaAuth();
  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal
  ] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [showingDisclaimerFromFooter, setIsDisclaimerFromFooter] = useState(
    false
  );
  const [showAutoSanitizationModal, setShowAutoSanitizationModal] = useState(
    false
  );

  const loadingFiles = useSelector((state) => state.home.loadingFiles);

  const deleteActionCompleted = useSelector(
    (state) => state.home.deleteActionCompleted
  );

  const onDeleteFile = (payload) => {
    deleteFileAction(payload);
    const fileToSelect = getSelectedFileAfterDelete(
      filesList,
      payload?.index || 0
    );
    setSelectedFile(fileToSelect);
  };

  useEffect(() => {
    const { hrId, profileImage } = userDetails;
    if (Number(hrId) > 0 && profileImage !== "") { // As user details state being updated twice,
      AAPageLoad("TAMS:Home", userDetails.email);
    }
  }, [userDetails]);
  useEffect(() => {
    if (deleteActionCompleted) {
      setShowDeleteConfirmationModal(false);
      resetHomeStore();
    }
  }, [deleteActionCompleted]);

  useEffect(() => {
    setSelectedPPTId(selectedFile.pptId);
  }, [selectedFile]);

  if (authState.isPending) {
    return <div>Loading...</div>;
  }

  return (
    <main className="home">
      <PageHeader />
      <Container className="home__container">
        <Grid>
          <Grid.Row>
            <Grid.Column
              className="home__upload-section upload-files"
              computer={7}
              mobile={14}
              tablet={14}
            >
              <div>
                <Header as="h4">{CONFIG.LABELS.UPLOAD_DOCUMENTS}</Header>
              </div>
              {loadingFiles ? (
                <Placeholder className="upload-files__upload-container-loader">
                  <Placeholder.Image square />
                </Placeholder>
              ) : (
                <UploadFiles
                  loadingDisclaimer={loadingFiles}
                  uploadPowerPoint={uploadPowerPoint}
                />
              )}
            </Grid.Column>
            <Grid.Column
              className="home__divider"
              computer={1}
              mobile={2}
              tablet={2}
            >
              <hr />
            </Grid.Column>
            <Grid.Column
              className="home__file-list-container file-list"
              computer={8}
              mobile={16}
              tablet={16}
            >
              {filesList.length && !loadingFiles ? (
                <FilesList
                  filesList={filesList}
                  setShowDeleteConfirmationModal={
                    setShowDeleteConfirmationModal
                  }
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  loadingFiles={loadingFiles}
                  setShowAutoSanitizationModal={setShowAutoSanitizationModal}
                />
              ) : loadingFiles ? (
                <FileListLoader />
              ) : (
                <>
                  <div>&nbsp;</div>
                  <FilesListPlaceholder />
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <DisclaimerModal
        showDisclaimerModal={showDisclaimer}
        setShowDisclaimerModal={setDisclaimer}
        disclaimerModalData={CONFIG.COMPONENT_DATA.disclaimerModalData}
        showingDisclaimerFromFooter={showingDisclaimerFromFooter}
        onCloseDisclaimer={() => setIsDisclaimerFromFooter(false)}
      />
      {/* Egnyt feature were partly developed, and can be found in the commit (commit id) fe5fad529650b9b3e078c7625d10d6491fd20c81 */}
      <DeleteConfirmationModal
        showDeleteConfirmationModal={showDeleteConfirmationModal}
        deleteConfirmationModalData={
          CONFIG.COMPONENT_DATA.deleteFileConfirmationModal
        }
        setShowDeleteConfirmationModal={setShowDeleteConfirmationModal}
        selectedFile={selectedFile}
        deleteFile={onDeleteFile}
        isDeletingFile={isDeletingFile}
      />
      {/* <Version /> */}
      <DisclaimerFooter
        label={CONFIG.COMPONENT_DATA.disclaimerFooterData.label}
        moreInfo={CONFIG.COMPONENT_DATA.disclaimerFooterData.moreInfo}
        copyright={CONFIG.COMPONENT_DATA.disclaimerFooterData.copyright}
        onShowDisclaimer={() => {
          setDisclaimer(true);
          setIsDisclaimerFromFooter(true);
        }}
      />
      <UserGuideFloatingIcon
        triggerFrom={CONFIG.CONSTANTS.USER_GUIDE_TRIGGERS.HOME}
      />
      <AutoSanitizationConfirmModal
        open={showAutoSanitizationModal}
        setShowAutoSanitizationModal={setShowAutoSanitizationModal}
        autoSanitize={autoSanitize}
        selectedFile={selectedFile}
        isAutoSanitizing={isAutoSanitizing}
      />
    </main>
  );
};

Home.propTypes = {
  filesList: PropTypes.arrayOf(fileListType),
  uploadPowerPoint: PropTypes.func,
  deleteFileAction: PropTypes.func,
  showDisclaimer: PropTypes.bool,
  setDisclaimer: PropTypes.func,
  isDeletingFile: PropTypes.bool,
  autoSanitize: PropTypes.func,
  isAutoSanitizing: PropTypes.bool,
  resetHomeStore: PropTypes.func,
  setSelectedPPTId: PropTypes.func,
  userDetails: PropTypes.object
};

Home.defaultProps = {
  filesList: [],
  uploadPowerPoint: noop,
  deleteFileAction: noop,
  showDisclaimer: false,
  setDisclaimer: noop,
  isDeletingFile: false,
  signedToken: {},
  autoSanitize: noop,
  isAutoSanitizing: false,
  resetHomeStore: noop,
  setSelectedPPTId: noop,
  userDetails: {}
};

const mapStateToProps = (state) => {
  return {
    filesList: getFilesList(state),
    showDisclaimer: getDisclaimerStatus(state),
    isDeletingFile: isDeletingDocument(state),
    isAutoSanitizing: isAutoSanitizing(state),
    signedToken: getSignedTokenFromStore(state),
    userDetails: getUserDetails(state)
  };
};

const mapDispatchToProps = {
  uploadPowerPoint: uploadPowerPointAction,
  setDisclaimer: setDisclaimerStatusAction,
  autoSanitize: autoSanitizeDocument,
  deleteFileAction,
  resetHomeStore,
  setSelectedPPTId
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
