import { currentTimeStamp } from "utils/common";
import * as at from "../types";

const initialState = {
  uploadingFiles: false,
  filesInProcess: [],
  deleteActionCompleted: false,
  loadingFiles: true,
  showDisclaimer: false,
  autoSanitized: false,
  moveToHomeFromPreview: false,
  selectedPPTId: null,
  pptLength: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case at.SET_DICLAIMER_STATUS:
      return {
        ...state,
        showDisclaimer: action.payload
      };
    case at.GET_FILES_SUCCEEDED:
      return {
        ...state,
        filesInProcess: action.payload.data,
        loadingFiles: false
      };

    case at.UPLOAD_POWER_POINT_FILES:
      return {
        ...state,
        filesInProcess: [action.payload, ...state.filesInProcess]
      };
    case at.UPDATE_FILE_STATUS_BY_ID: {
      if (action.payload) {
        const newFileList = state.filesInProcess.map((fileObj) => ({
          ...fileObj,
          pptStatus: action.payload[fileObj.pptId]
            ? action.payload[fileObj.pptId]
            : fileObj.pptStatus
        }));
        return {
          ...state,
          filesInProcess: newFileList
        };
      }
      return state;
    }
    case at.UPLOAD_POWER_POINT_FILES_SUCCEEDED:
    case at.RETRY_FAILED_DOCUMENT_SUCCEEDED: {
      if (action.payload?.data) {
        const newFileList = state.filesInProcess.map((fileObj) => {
          if (fileObj.pptId === action.payload.data?.pptId) {
            return {
              ...fileObj,
              ...action.payload.data,
              lastRetried: currentTimeStamp()
            };
          }
          return fileObj;
        });
        return {
          ...state,
          filesInProcess: newFileList
        };
      }
      return state;
    }
    case at.DELETE_FILE_SUCCEEDED:
      return {
        ...state,
        deleteActionCompleted: true,
        filesInProcess: state.filesInProcess.filter(
          (file) => file.pptId !== action.payload?.data
        )
      };
    case at.SET_SELECTED_PPT_ID:
      return {
        ...state,
        selectedPPTId: action.payload ? action.payload : state.selectedPPTId // TODO: check this issue (clearing after)
      };
    case at.RESET_HOME_STORE:
      return {
        ...state,
        deleteActionCompleted: false,
        autoSanitized: false
      };
    default:
      return state;
  }
};
